import * as React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import {Container, Col, Row} from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css';


import SwiperCore, {
    Lazy
  } from 'swiper';

// Import Swiper styles
import 'swiper/css';

const Portfolio = () => (
      <Container>
    <Seo title="Portfolio" />
    <Row className="w-100">
      <Col className="bg-dark">
        
      </Col>
    </Row>
    <Row>
      <Col>
      </Col>
    </Row>
{/*       
          <StaticQuery
    query={graphql`
    {
        allGraphCmsPiece {
            edges {
              node {
                titlte
                pieceTitle
                productVideo {
                  url
                }
              }
            }
          }
      }
    `}
    render={data => 
        <Swiper
        spaceBetween={50}
        slidesPerView={1}
        autoplay={true}
      >{data.allGraphCmsPiece.edges.map(({ node }) => {
        return ( <><p>{node.titlte}</p><p>{node.pieceTitle}</p><video width="400" controls muted autoplay>
        <source src={node.productVideo.url} type="video/mp4" />
    </video></>)
    })}</Swiper>
}
  ></StaticQuery> */}
    
    </Container>
)

export default Portfolio